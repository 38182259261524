<template>
  <div class="account-user-child">
    <div v-if="isMobile" class="card-margin">
      <el-table
        style="width: 100%; height: 175px;"
        :indent="28"
        :data="accountUserSummaryTableData"
        row-key="name"
        stripe
        lazy
      >
        <el-table-column align="center">
          <template slot="header">
            <div>
              <span class="font-weight color-main">进行中的任务</span>
              <el-button
                :underline="false"
                type="text"
                style="float: right;"
                @click="onJumpScenePage"
              >
                <span>任务明细
                  <i class="el-icon-arrow-right" />
                </span>
              </el-button>
            </div>
          </template>

          <el-table-column
            prop="name"
            align="center"
            min-width="100"
            label="任务类型"
          >
            <!-- eslint-disable-next-line -->
            <template slot="header" slot-scope="scope">
              <div>任务类型</div>
            </template>
            <template slot-scope="scope">
              <span>{{ scope.row.name || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="meeting" align="center" label="学术调研委托服务">
            <template slot-scope="scope">
              <span>{{ scope.row.delegateMoney|| 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="represent" align="center" label="员工培训">
            <template slot-scope="scope">
              <span>{{ scope.row.represent || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="red" align="center" label="直播红包">
            <template slot-scope="scope">
              <span>{{ scope.row.red || 0 }}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div class="flex-around vertical-center desc-row mgn-t10">
        <div class="desc-item">
          <el-popover placement="top-start" title="账户当日期初余额为账户前一日期末余额" trigger="hover">
            <span slot="reference" class="color-main">期初余额&emsp;<i class="el-icon-question" /></span>
          </el-popover>
          <div class="font-s28 font-weight">{{ accountUserSummaryData.startBalance || 0 }}</div>
          <div class="color-desc">
            截止至<br>
            {{
              $moment()
                .subtract(1, 'day')
                .endOf('day')
                .format('YYYY.MM.DD HH:mm:ss')
            }}
          </div>
        </div>
        <div class="desc-item self-align-center">
          <el-divider direction="vertical" />
        </div>
        <div class="desc-item">
          <span class="color-main">实时可支配余额</span>
          <div class="font-s28 font-weight">{{ accountUserSummaryData.disposableAmount || 0 }}</div>
          <el-link
            v-if="isTopOrg"
            :underline="false"
            type="primary"
            style="display: block;"
            @click="fundSetting"
          >
            资金管理设置
          </el-link>
          <el-button
            v-if="$auth('fin.point.trans')"
            size="medium"
            class="trans-btn"
            type="primary"
            @click="onJumpTransfer"
          >
            转账
          </el-button>
          <div class="desc-item self-align-center mgn-t10">
            <el-link :underline="false" type="primary" @click="onJumpSd">
              <span>业务明细</span>
              <i class="el-icon-arrow-right" />
            </el-link>
          </div>
        </div>
      </div>
    </div>
    <el-row
      v-else
      type="flex"
      :gutter="16"
      class="card-margin"
    >
      <el-col :xs="12" :sm="12" :lg="14">
        <el-table
          style="width: 100%;"
          :indent="28"
          :data="accountUserSummaryTableData"
          row-key="name"
          stripe
          lazy
        >
          <el-table-column align="center">
            <template slot="header">
              <div>
                <span class="font-weight color-main">进行中的任务</span>
                <el-button
                  :underline="false"
                  type="text"
                  style="float: right;"
                  @click="onJumpScenePage"
                >
                  <span>任务明细
                    <i class="el-icon-arrow-right" />
                  </span>
                </el-button>
              </div>
            </template>

            <el-table-column
              prop="name"
              align="center"
              min-width="100"
              label="任务类型"
            >
              <!-- eslint-disable-next-line -->
              <template slot="header" slot-scope="scope">
                <div>任务类型</div>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.name || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="meeting" align="center" label="学术调研委托服务">
              <template slot-scope="scope">
                <span>{{ scope.row.academic || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="represent" align="center" label="员工培训">
              <template slot-scope="scope">
                <span>{{ scope.row.represent || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="red" align="center" label="直播红包">
              <template slot-scope="scope">
                <span>{{ scope.row.red || 0 }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col
        :xs="12"
        :sm="12"
        :lg="10"
        class="flex-around vertical-center desc-row"
      >
        <div class="desc-item">
          <el-popover placement="top-start" title="账户当日期初余额为账户前一日期末余额" trigger="hover">
            <span slot="reference" class="color-main">期初余额&emsp;<i class="el-icon-question" /></span>
          </el-popover>
          <div class="font-s28 font-weight">{{ accountUserSummaryData.startBalance || 0 }}</div>
          <div class="color-desc">
            截止至<br>
            {{
              $moment()
                .subtract(1, 'day')
                .endOf('day')
                .format('YYYY.MM.DD HH:mm:ss')
            }}
          </div>
        </div>
        <div class="desc-item self-align-center">
          <el-divider direction="vertical" />
        </div>
        <div class="desc-item">
          <span class="color-main">实时可支配余额</span>
          <div class="font-s28 font-weight">{{ accountUserSummaryData.disposableAmount || 0 }}</div>
          <el-link
            v-if="isTopOrg"
            :underline="false"
            type="primary"
            style="display: block;"
            @click="fundSetting"
          >
            资金管理设置
          </el-link>
          <el-button
            v-if="$auth('fin.point.trans')"
            size="medium"
            class="trans-btn"
            type="primary"
            @click="onJumpTransfer"
          >
            转账
          </el-button>
        </div>
        <div class="desc-item self-align-center">
          <el-link :underline="false" type="primary" @click="onJumpSd">
            <span>业务明细</span>
            <i class="el-icon-arrow-right" />
          </el-link>
        </div>
      </el-col>
    </el-row>
    <template v-if="$auth('fin.point.viewExp')">
      <el-divider v-if="!isMobile" />
      <div class="font-weight">
        下级组织账户总览
      </div>
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        class="search-row flex-wrap"
      >
        <div>
          <el-date-picker
            :value="[startDate, endDate]"
            :disabled="isExport"
            type="daterange"
            range-separator="—"
            unlink-panels
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy年MM月dd日"
            :picker-options="pickerOptions"
            :editable="false"
            @change="onChangeDate"
          />
        &emsp;
          <el-radio-group v-model="activeRadio" @change="onChangeRadio">
            <el-radio-button v-for="item in radioList" :key="item.key" :label="item.key">
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
        &emsp;
          <el-cascader
            v-model="orgIds"
            :options="orgList"
            :props="cascaderProps"
            :show-all-levels="false"
            placeholder="选择组织"
            @change="onSelectOrg"
          />
        </div>
        <el-row
          v-if="!isMobile"
          type="flex"
          justify="space-between"
          align="middle"
        >
          <el-button plain class="plain-button-colored" @click="exportDialogVisible = true">导出数据</el-button>
        </el-row>
      </el-row>
      <div>
        <el-table
          v-if="!reqUpdate"
          v-loading="loading"
          style="width: 100%;"
          :indent="28"
          :data="initAccountUserList"
          :expand-row-keys="defaultOpenArr"
          row-key="orgId"
          stripe
          lazy
          :load="onOpenOrgload"
          :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            prop="orgName"
            min-width="180"
            label="组织名称"
            fixed="left"
            align="left"
            show-overflow-tooltip
          >
            <template slot="header">
              <div class="font-weight color-main align-center">组织名称</div>
            </template>
            <template slot-scope="scope">
              <div class="org-name-box">
                <el-tooltip
                  v-if="scope.row.status !== 1"
                  effect="light"
                  content="该组织已被冻结"
                  placement="bottom"
                >
                  <img src="@/assets/nav/forbid.png" alt="">
                </el-tooltip>
                {{ scope.row.orgName || '—' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="支出">
            <template slot="header">
              <div class="font-weight color-main">支出</div>
            </template>
            <el-table-column
              width="110"
              prop="questionnaireMoney"
              align="center"
              label="学术调研委托"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.delegateMoney || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="110"
              prop="trainMoney"
              align="center"
              label="员工培训"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.trainMoney || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="110"
              prop="redPacketMoney"
              align="center"
              label="直播红包"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.redPacketMoney || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="paySubTotal"
              align="center"
              width="110"
              label="支出小计"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.paySubTotal || 0 }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column align="center" label="内部收支（充值及转账收支）">
            <template slot="header">
              <div class="font-weight color-main">内部收支（充值及转账收支）</div>
            </template>
            <el-table-column
              prop="chargeIncomeMoney"
              align="center"
              width="110"
              label="充值收入"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.chargeIncomeMoney || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="transferIncomeMoney"
              align="center"
              width="110"
              label="转账收入"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.transferIncomeMoney || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="otherIncome"
              align="center"
              width="110"
              label="其它收入"
            >
              <template slot="header">
                <el-tooltip class="item" effect="dark" placement="top-start">
                  <div slot="content">
                    <div>平台委托退回相关积分</div>
                  </div>
                  <span>其它收入<i class="el-icon-question" /></span>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.otherIncome || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="transferMoney"
              align="center"
              width="110"
              label="转账支出"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.transferMoney || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="incomeSubTotal"
              align="center"
              width="110"
              label="小计"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.incomeSubTotal || 0 }}</span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :render-header="renderStartDate"
            prop="startBalance"
            align="center"
            width="150"
            label="期初结余"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.startBalance || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :render-header="renderEndDate"
            prop="balance"
            align="center"
            width="150"
            label="期末结余"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.balance || 0 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120" align="center">
            <template slot="header">
              <div class="font-weight color-main">操作</div>
            </template>
            <template slot-scope="scope">
              <div v-show="scope.row.account !== '总计'">
                <span class="text-primary-link" @click="onJumpDetail(scope.row)">详情</span>
                <el-popover
                  :key="scope.row.orgId"
                  :value="openObj[scope.row.orgId]"
                  width="200"
                  placement="left"
                  @show="onShowDownToast(scope.row.orgId)"
                >
                  <div v-if="exportMsg" class="lay-box">{{ exportMsg }}</div>
                  <div v-else class="lay-box">是否下载{{ startDate }}至{{ endDate }}的数据？</div>
                  <el-row type="flex" justify="end">
                    <el-button size="mini" type="text" @click="onCloseToast(scope.row.orgId)">取消</el-button>
                    <el-button
                      v-if="exportMsg"
                      type="primary"
                      size="mini"
                      @click="onJumpDown"
                    >
                      前往
                    </el-button>
                    <el-button
                      v-else
                      type="primary"
                      size="mini"
                      :loading="isLoading"
                      @click="onDown(scope.row.orgId)"
                    >
                      确定
                    </el-button>
                  </el-row>
                  <span slot="reference" class="margin-l text-primary-link">下载</span>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-row type="flex" justify="end" class="t-p">
          <el-pagination
            background
            hide-on-single-page
            layout="prev, pager, next"
            :page-size="pageSize"
            :current-page="pageNo"
            :total="totalCount"
            @current-change="onChange"
          />
        </el-row>
      </div>
    </template>
    <el-dialog
      append-to-body
      :visible.sync="exportDialogVisible"
      width="450px"
      title="导出数据"
    >
      <el-row
        type="flex"
        justify="space-around"
        align="middle"
        class="export-dialog"
      >
        <div @click="handleExportMarketingList">
          <img src="@/assets/common/summary_sheet.png" alt="">
          <div class="color-main">导出汇总表</div>
          <div class="color-info">导出所选组织的财务汇总数据</div>
        </div>
        <el-divider direction="vertical" />
        <div @click="handleExportMarketingList('new')">
          <img src="@/assets/common/detail_sheet.png" alt="">
          <div class="color-main">导出明细表</div>
          <div class="color-info">导出所选组织的财务明细数据</div>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { isMobile } from '@/utils/util.js'
// const camelCaseAnalyzeReg = new RegExp('(?<=[^A-Z])[A-Z][A-Z]+(?![^A-Z])|(.[^A-Z]+(?=[A-Z]|$))', 'g')
// const camelCaseAnalyzeReg = new RegExp('[A-Z]+(?![^A-Z])|(.[^A-Z]+(?=[A-Z]|$))', 'g')
export default {
  name: 'AccountUserChild',
  components: {},
  data() {
    return {
      reqUpdate: true,
      orgIds: [],
      radioList: [
        { name: '近一周', key: 'week' },
        { name: '近一月', key: 'month' },
        { name: '近三月', key: 'three_month' }
      ],
      activeRadio: 'week', // message or download
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      exportMsg: '',
      showMask: false,
      flowType: 1, // 1 积分 2 直播服务费
      openObj: {}, // 页面弹窗控制对象
      cardBodyStyle: {
        paddingTop: '40px',
        paddingLeft: '36px',
        paddingRight: '36px',
        paddingBottom: '40px'
      },
      cascaderProps: {
        children: 'child',
        label: 'orgName',
        value: 'orgId',
        disabled: 'isSelect',
        checkStrictly: true
      },
      exportDialogVisible: false,
      selectedOrgId: '',
      startDate: `${this.$moment()
        .add(-1, 'weeks')
        .format('YYYY-MM-DD')}`,
      endDate: `${this.$moment()
        .add(-1, 'days')
        .format('YYYY-MM-DD')}`,
      isExport: false,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.startDate = `${this.$moment(minDate).format('YYYY-MM-DD')}`
          this.endDate = `${this.$moment(maxDate).format('YYYY-MM-DD')}`
        },
        disabledDate: current => {
          // const tooLate = this.startDate && this.$moment(current).diff(this.$moment(this.startDate), 'days') > 365;
          // const tooEarly = this.endDate && this.$moment(this.endDate).diff(this.$moment(current), 'days') > 365;
          const currentMin = current < this.$moment('2018-01-01')
          const currentMax = current > this.$moment().add(-1, 'days')
          return currentMax || currentMin
        }
      },
      loading: false,
      isLoading: false,
      moneyArr: ['N/A', 'meetingMoney', 'questionnaireMoney', 'liveMoney', 'webinarMoney', 'visitMoney', 'trainMoney', 'otherIncome', 'paySubTotal', 'transferIncomeMoney', 'chargeIncomeMoney', 'transferMoney', 'incomeSubTotal', 'balance'],
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapGetters(['accountUserList', 'accountUserSummaryData', 'orgList', 'isTopOrg']),
    initAccountUserList() {
      const list = []
      if (this.accountUserList[0]?.orgId) {
        list.push({ ...this.accountUserList[0], hasChildren: null })
      }
      return list
    },
    defaultOpenArr() {
      return this.initAccountUserList.map(item => item.orgId)
    },
    orgChildList() {
      return this.orgList[0] ? this.orgList[0].child : []
    },
    accountUserSummaryTableData() {
      const tableData = [
        {name: '总任务积分', academic: this.accountUserSummaryData.totalCodeGift, represent: this.accountUserSummaryData.totalTrainGift, red: this.accountUserSummaryData.totalRedPacketGift },
        {name: '待领取积分', academic: this.accountUserSummaryData.totalWaitingReceiveGift, represent: this.accountUserSummaryData.representTrainGift, red: this.accountUserSummaryData.redPacketGift	 }
      ]
      return tableData
    }
  },
  watch: {
    orgList: {
      handler(val) {
        if (val && val[0]) {
          this.orgIds = [val[0].orgId]
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions(['onDownAccountData', 'getAccountUserList', 'getAccountUserChildrenList', 'downloadAccountCollectList', 'downloadAllDetailList', 'downloadDelegationAllCollectList']),
    onOpenOrgload(tree, treeNode, resolve) {
      console.log(tree, treeNode)
      const req = {
        flowType: this.flowType,
        targetOrgId: tree.orgId,
        startAt: this.startDate,
        endAt: this.endDate
      }
      this.loading = true
      this.getAccountUserChildrenList(req)
        .then(res => {
          resolve(res)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleExportMarketingList(type) {
      const req = {
        flowType: this.flowType,
        targetOrgId: this.selectedOrgId,
        startAt: this.startDate,
        endAt: this.endDate
      }
      const request = type === 'all' ? this.downloadAllDetailList : type === 'new' ? this.downloadDelegationAllCollectList : this.downloadAccountCollectList
      request(req).then(
        res => {
          this.$message.success(res)
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
      this.exportDialogVisible = false
    },
    onDown(id) {
      if (!this.$moment(this.startDate).isValid()) return this.$message.error('请选择开始日期')
      if (!this.$moment(this.endDate).isValid()) return this.$message.error('请选择结束日期')
      if (this.isLoading) return
      this.isLoading = true
      const req = {
        targetOrgId: id,
        startAt: this.startDate,
        flowType: this.flowType,
        endAt: this.endDate
      }
      this.onDownAccountData(req).then(
        res => {
          this.isLoading = false
          this.exportMsg = res.data
          this.$message.success('数据下载成功，请前往下载中心查看')
          this.onCloseToast(id)
        },
        rea => {
          this.isLoading = false
          this.$message.error(rea.message)
        }
      )
    },
    onJumpDown() {
      this.$router.push({name: 'DownloadCenter'})
    },
    onSelectOrg(orgs) {
      const list = [...orgs]
      this.selectedOrgId = list.pop()
      this.init()
    },
    init() {
      if (!this.$moment(this.startDate).isValid()) return this.$message.error('请选择开始日期')
      if (!this.$moment(this.endDate).isValid()) return this.$message.error('请选择结束日期')
      this.loading = true
      this.reqUpdate = true
      const req = {
        flowType: this.flowType,
        targetOrgId: this.selectedOrgId,
        startAt: this.startDate,
        endAt: this.endDate
      }
      this.getAccountUserList(req).then(() => {
        this.loading = false
        this.reqUpdate = false
        this.exportMsg = ''
      }, rea => {
        this.loading = false
        this.$message.error(rea.message)
      })
    },
    onJumpSd() {
      this.$router.push('/account/user/business')
    },
    onChangeRadio(val) {
      this.activeRadio = val
      switch (this.activeRadio) {
        case 'week':
          this.startDate = this.$moment()
            .add(-1, 'weeks')
            .format('YYYY-MM-DD')
          this.endDate = this.$moment()
            .add(-1, 'days')
            .format('YYYY-MM-DD')
          break
        case 'month':
          this.startDate = this.$moment()
            .add(-1, 'months')
            .format('YYYY-MM-DD')
          this.endDate = this.$moment()
            .add(-1, 'days')
            .format('YYYY-MM-DD')
          break
        case 'three_month':
          this.startDate = this.$moment()
            .add(-3, 'months')
            .format('YYYY-MM-DD')
          this.endDate = this.$moment()
            .add(-1, 'days')
            .format('YYYY-MM-DD')
          break
      }
      this.init()
    },
    onChangeDate(dateArray) {
      this.activeRadio = ''
      if (!dateArray) {
        this.onChangeRadio('week')
      } else if (dateArray && dateArray.length === 2) {
        this.init()
      }
    },
    onCloseToast(openKey = '') {
      this.exportMsg = ''
      this.showMask = false
      this.$set(this.openObj, openKey, false)
    },
    onShowDownToast(openKey = '') {
      if (this.openObj[openKey]) return
      this.exportMsg = ''
      this.showMask = true
      this.$nextTick(() => {
        this.$set(this.openObj, openKey, true)
      })
    },
    onJumpTransfer() {
      this.$router.push('/account/user/transfer')
    },
    onJumpScenePage() {
      this.$router.push('/account/invitecode')
    },
    onJumpDetail(item = {}) {
      this.$router.push(`/account/payment/detail/gredit/${item.orgId}?startDate=${this.startDate}&endDate=${this.endDate}&name=${item.account || ''}`)
    },
    onOpenCol() {
      this.isOpenCol = !this.isOpenCol
    },
    onChange(pageNo) {
      this.pageNo = pageNo
    },
    fundSetting() {
      this.$emit('setting')
    },
    renderStartDate(h) {
      return h('div', { attrs: { class: 'font-weight color-main' } }, [`截止${this.startDate}`, h('br'), '期初结余'])
    },
    renderEndDate(h) {
      return h('div', { attrs: { class: 'font-weight color-main' } }, [`截止${this.endDate}`, h('br'), '期末结余'])
    }
  }
}
</script>

<style lang="scss">
.org-name-box {
  display: inline;
  img {
    vertical-align: middle;
    margin-right: 5px;
  }
}
.account-user-child .el-table--border th {
  border-bottom: 1px solid #e1e1e1;
}
.account-user-child .el-table--border th,
.el-table--border td {
  border-right: 1px solid #e1e1e1;
}
.account-user-child .el-table th:first-child > .cell {
  padding-left: 10px;
}
.account-user-child .el-table td:first-child > .cell {
  padding-left: 10px;
}
</style>
<style lang="scss" scoped>
.font-title {
  font-size: 36px;
  padding-top: 10px;
  padding-bottom: 15px;
}
.row-t {
  height: 220px;
}
.ly-box {
  position: relative;
  overflow: hidden;
  min-width: 200px;
}
.color-in-s {
  color: rgb(180, 180, 180);
}
.ly-box-p {
  padding-top: 50px;
  padding-bottom: 50px;
}
.v-tips {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 20px;
}
.v-tips-bg {
  background-image: url('../../assets/common/scene_code_bg.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 24px;
  height: 14px;
  img {
    height: 10px;
  }
}
.ly-image {
  margin-right: 6px;
  flex-shrink: 0;
}
.trans-btn {
  position: relative;
  z-index: 10;
  width: 110px;
}
.margin-l {
  margin-left: 14px;
}
.nav-box {
  padding-top: 30px;
  padding-bottom: 30px;
}
.card-margin {
  margin-bottom: 10px;
  // height: 175px;
}
.lay-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.table-header-row {
  padding-left: 20px;
}
.desc-row {
  padding: 10px 8px;
  align-items: flex-start;
}
.self-align-center {
  align-self: center;
}
.desc-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  line-height: 1.9;
  min-height: 47px;
  button {
    transform: translateY(8px);
  }
  .el-divider {
    font-size: 47px;
  }
  i {
    color: $color-info;
  }
}
.search-row {
  padding: 12px 0;
}
.plain-button-colored {
  background: #fff;
  border-color: $color-primary;
  color: $color-primary;
  outline: none;
}
.export-dialog {
  padding: 20px;
  text-align: center;
  line-height: 1.75;
  div {
    cursor: pointer;
  }
  img {
    width: 30px;
    height: 35px;
    margin-bottom: 5px;
  }
  .el-divider {
    font-size: 37px;
  }
}
</style>
